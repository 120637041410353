<template>
  <div class="change-unit-price">
    <div class="card">
      <fd-form @submit="submitForm">
        <modal-body class="p-2">
          <div class="container fluid">
            <h3 class="mt-2 mb-4">Change Price</h3>

            <div class="unit-name mb-2">{{ unit.plot }}, {{ unit.street }}</div>
            <!-- Sale Price -->
            <fd-input
              v-model="form.salePrice"
              class="col-12 px-1 mb-2"
              label="Sale Price (RM)"
              name="salePrice"
              type="number"
              :validators="[validator.price]"
              @input="updatePrice"
            >
            </fd-input>
            <!-- Unit Price Type -->
            <fd-select
              v-model="pricePerUnitOrigin"
              class="col-12 px-1 mb-2"
              label="Unit Price Calculate With"
              name="unitPriceType"
              :options="unitPriceCalculateOptions"
              :optionValue="
                (option) => {
                  return option;
                }
              "
              :optionLabel="
                (option) => {
                  return option;
                }
              "
              :validators="[validator.required]"
              @change="updatePrice"
            >
            </fd-select>
            <div class="col-12">
              <div class="sales-price-per-unit mx-1">
                <label class="label">Sales Price per Unit</label>
                <p>
                  RM{{ form.salePricePerUnit || "0.00" }}
                  {{ unit.projectUnitFloorPlan.areaUnitType }}
                </p>
              </div>
            </div>
          </div>
        </modal-body>
        <!-- Action Footer -->
        <modal-footer class="p-2">
          <div class="col-12 text-right">
            <button type="button" class="btn mr-1" @click="$emit('cancel')">
              Cancel
            </button>
            <button class="btn main">Update</button>
          </div>
        </modal-footer>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  price
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {
    unit: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      validator: {
        required,
        price
      },

      form: {
        salePrice: "",
        salePricePerUnit: ""
      },
      unitPriceCalculateOptions: ["Built Up", "Land"],
      pricePerUnitOrigin: "Built Up"
    };
  },
  computed: {
    areaValue: function () {
      let val = 0;
      if (this.pricePerUnitOrigin == "Built Up") {
        val = this.unit.builtUpArea;
      } else if (this.pricePerUnitOrigin == "Land") {
        val = this.unit.landArea;
      }
      return val;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    // Assign the current status
    this.form = {
      salePrice: this.unit.salePrice,
      salePricePerUnit: this.unit.salePricePerUnit
    };
  },
  methods: {
    updatePrice() {
      this.form = {
        ...this.form,
        salePricePerUnit: this.updateUnitPrice(this.form.salePrice)
      };
    },
    updateUnitPrice(salePrice = 0) {
      if (salePrice == 0 || this.areaValue == 0) {
        return "0.00";
      }

      return this.calculateUnitPrice(salePrice, this.areaValue);
    },
    calculateUnitPrice(price, unit) {
      try {
        let unitPrice = (
          Math.round((parseFloat(price) / parseFloat(unit)) * 100) / 100
        ).toFixed(2);

        return unitPrice;
      } catch (error) {
        return 0;
      }
    },
    submitForm() {
      this.$emit("submit", this.unit.id, this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.change-unit-price {
  .card {
    min-width: 400px;
  }
  .unit-name {
    border: solid 1px $color-secondary;
    color: $color-secondary;
    padding: 6px 12px;
    border-radius: 5px;
    width: fit-content;
  }
  .sales-price-per-unit {
    border: solid 1px #00000025;
    padding: 15px 15px;
    border-radius: 5px;
    .label {
      font-weight: bold;
    }
  }
}
</style>
